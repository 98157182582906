import ReactGA from 'react-ga4';

let initialized = false;

const setConsent = (consent) => {
  if(!window.gtag) return;
  ReactGA.gtag('consent', 'update', {
    'analytics_storage': consent === 'accepted' ? 'granted' : 'denied',
    'ad_storage': consent === 'accepted' ? 'granted' : 'denied',
    "personalization_storage": consent === 'accepted' ? 'granted' : 'denied',
    "ad_user_data": consent === 'accepted' ? 'granted' : 'denied',
    "ad_personalization": consent === 'accepted' ? 'granted' : 'denied',
    "security_storage": consent === 'accepted' ? 'granted' : 'denied',
  });
}


// Simple key obfuscation : To avoid exposing the key in the code
const decode = (str) => {
  return atob(str);
};

// Check if user is in EU
function isEUCountry(countryCode) {
  const euCountries = ['AT', 'BE', 'BG', 'HR', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];
  return euCountries.includes(countryCode);
}

// Get cookie
function getCookie(name) {
  let nameEq = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEq) == 0) return c.substring(nameEq.length, c.length);
  }
  return null;
}

const GEOAPIFY_KEY = 'YTNlMjRjYmJhODUxNDNhY2IxNmE0MDM2ZDVlYTgxNTY=';

export const initialize = () => {
  // Checking if consent was previously accepted or denied
  const consent = localStorage.getItem('cookieConsent') || getCookie('cookieConsent');

  // If consent was previously accepted, initialize GA
  if (consent && consent === 'accepted' && !initialized) {
    ReactGA.initialize([
      {
        "trackingId": "G-ENDMKDPL5B"
      },
      {
        "trackingId": "UA-147420730-1"
      }
    ])
    // set consent granted to Google Analytics
    setConsent('accepted');
    initialized = true;
  } else if(consent && consent === 'denied' && !initialized){
    setConsent('denied');
    initialized = true;
  } else{
    // For the new users : coming first time to the website
    fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=${decode(GEOAPIFY_KEY)}`)
    .then(resp => resp.ok ? resp.json() : Promise.reject())
    .then((userLocationData) => {
      // Check if user is in EU (only if we have valid data)
      const euCountry = userLocationData?.country?.iso_code 
        ? isEUCountry(userLocationData.country.iso_code)
        : true; // Assume EU if data is invalid (safer default)

      if (!euCountry) {
        ReactGA.initialize([
          {
            "trackingId": "G-ENDMKDPL5B"
          },
          {
            "trackingId": "UA-147420730-1"
          }
        ]);
        initialized = true;
        setConsent('accepted');
      }else{
        setConsent('denied');
      }
    })
    .catch(() => {
      // Silently handle any errors by defaulting to denied consent
      setConsent('denied');
    });
  }
};

export const event = (...args) => {
  const consent = localStorage.getItem('cookieConsent') || getCookie('cookieConsent');
  if (consent === 'accepted') {
    ReactGA.event(...args);
  }
}

export const send = (...args) => {
  const consent = localStorage.getItem('cookieConsent') || getCookie('cookieConsent');
  if (consent === 'accepted') {
    ReactGA.send(...args)
  }
} 
