import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify"
import { useSearchParams, useNavigate } from "react-router-dom";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
	Paper,
	Button,
	Typography,
	TextField,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	useMediaQuery,
}
	from '@mui/material';
import { Trans, useTranslation } from 'react-i18next'

const bodyDivStyle = {
	margin: "auto",
	textAlign: "center",
	width: "900px",
}

const containerStyle = {
	// display: "flex",
	overflow: "hidden",
	width: "100%",
	height: "100%",
	position: "relative",
};

// Should be different if logged in :|
const Contact = (props) => {
	const { globalUrl, surfaceColor, newColor, mini, textColor, inputColor } = props;
	const { t } = useTranslation('');
	const [dealCountry, setDealCountry] = React.useState("United States");
	const [dealValue, setDealValue] = React.useState("");
	const [dealType, setDealType] = React.useState("MSSP");

	const products = [
		{ code: '', label: 'MSSP', phone: '' },
		{ code: '', label: 'Enterprise', phone: '' },
		{ code: '', label: 'Consultancy', phone: '' },
		{ code: '', label: 'Support', phone: '' },
	]

	const minimize = mini !== undefined && mini
	const textcolor = textColor !== undefined ? textColor : "#ffffff"
	const inputcolor = newColor !== undefined ? newColor : inputColor

	const boxStyle = {
		display: "flex",
		flexDirection: "column",
		padding: 60,
		width: "40%"
	}

	const bodyTextStyle = {
		color: "#ffffff",
		textAlign: "center"
	}

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [title, setTitle] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [category, setCategory] = useState("");
	const [phone,] = useState("");
	const [message, setMessage] = useState("");
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [formMessage, setFormMessage] = useState("");
	const [step, setStep] = useState(1); 
	const isMobile = useMediaQuery('(max-width: 768px)'); 
	const [emailError, setEmailError] = useState(false); 
	const [showThankYouMessage, setShowThankYouMessage] = useState(false);

	const swipeStyle = {
		display: "flex",
		// transform: `translateX(${isMobile ? null : step === 1 ? "25%" : "25%"})`,
		transition: "transform 0.5s ease-in-out",
	};

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleEmailChange = (e) => {
		const emailValue = e.target.value;
		setEmail(emailValue);
		setEmailError(!validateEmail(emailValue));
	};

	const submitContact = () => {
		if (emailError) {
			toast("Please enter a valid email address.");
			return;
		}
		const data = {
			"firstname": firstname,
			"lastname": lastname,
			"title": title,
			"companyname": company,
			"email": email,
			"phone": phone,
			"message": message,
			"Category": category,
		};
		console.log("data", data);

		fetch(globalUrl + "/api/v1/contact", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(response => {
				if (response.success === true) {
					setFormMessage(response.message);
					toast("Thanks for submitting!");
					setMessage("");
					if (category === "Book a Demo") {
						setStep(2);
					}
				} else {
					const msg = "Something went wrong. Please contact frikky@shuffler.io directly.";
					if (response.reason !== undefined && response.reason !== null) {
						setFormMessage(response.reason);
					} else {
						setFormMessage(msg);
						toast(msg);
					}
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	const categoryMap = {
		book_a_demo: "Book a Demo",
		talk_to_sales: "Talk to Sales",
		contact: "Contact",
		partnership: "Partnership",
		support: "Support",
		technical_question: "Technical Question",
		security_consultation: "Security Consultation",
		cloud_enterprise_plan: "Cloud Enterprise Plan",
		onprem_enterprise_plan: "On-Premise Enterprise Plan",
	};
	const reverseCategoryMap = Object.fromEntries(
		Object.entries(categoryMap).map(([key, value]) => [value, key])
	);

	useEffect(() => {
		const categoryFromUrl = searchParams.get("category");

		if (categoryFromUrl && categoryMap[categoryFromUrl]) {
			const formattedCategory = categoryMap[categoryFromUrl];
			if (formattedCategory !== category) {
				console.log("Setting category from URL:", formattedCategory);
				setCategory(formattedCategory);
			}
		}
	}, [searchParams, category]);

	const handleChange = (event) => {
		const selectedCategory = event.target.value;
		console.log("User selected category:", selectedCategory);
		setCategory(selectedCategory);

		const formattedUrlCategory = reverseCategoryMap[selectedCategory];
		navigate(`/contact?category=${formattedUrlCategory}`, { replace: true });
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const openDriftChat = () => {
		if (window.drift) {
			window.drift.api.openChat();
		} else {
			console.error("Drift is not initialized.");
		}
	};

	const handleCalendlySuccess = () => {
		setShowThankYouMessage(true);
		// setStep(1); 
	};

	const calendlyEmbed = (
		<div style={{
			width: "100%",
			boxSizing: "border-box",
			textAlign: "center",
			backgroundColor: "#212121",
			borderRadius: "16px",
		}}>
			{!showThankYouMessage ? (
				<>
					<Typography variant="h6" style={{ color: "#FFFFFF", marginBottom: "10px" }}>
						<Trans t={t}>Schedule a Demo</Trans>
					</Typography>
					<iframe
						src="https://calendly.com/sushant-shuffler/30min"
						frameBorder="0"
						style={{
							border: "none",
							borderRadius: "8px",
							width: "100%",
							height: "100%",
							minHeight: "900px",
							backgroundColor: "#212121",
							overflow: "hidden",
						}}
						onLoad={() => {
							setTimeout(handleCalendlySuccess, 30000);
						}}
					></iframe>
					<Button
						style={{
							marginTop: "20px",
							backgroundColor: "#FF8544",
							color: "#ffffff",
							textTransform: "capitalize",
							fontSize: "16px",
							borderRadius: "8px",
						}}
						variant="contained"
						onClick={() => setStep(1)}
					>
						<Trans t={t}>Back</Trans>
					</Button>
				</>
			) : (
				<div style={{ padding: "20px", color: "#FFFFFF" }}>
					<Typography variant="h6" style={{ marginBottom: "10px" }}>
						<Trans t={t}>Thank you for scheduling a demo!</Trans>
					</Typography>
					<Typography variant="body1">
						<Trans t={t}>We look forward to seeing you on the call soon.</Trans>
					</Typography>
					<Button
						style={{
							marginTop: "20px",
							backgroundColor: "#FF8544",
							color: "#ffffff",
							textTransform: "capitalize",
							fontSize: "16px",
							borderRadius: "8px",
						}}
						variant="contained"
						onClick={() => setStep(1)}
					>
						<Trans t={t}>Back to Contact</Trans>
					</Button>
				</div>
			)}
		</div>
	);

	// Random names for type & autoComplete. Didn't research :^)
	const landingpageDataBrowser =
		<div style={{
			padding: isMobile ? "10px" : "20px",
			boxSizing: "border-box",
			width: isMobile ? "100%" : "1300px",
			margin: "0 auto",
			color: "#FFFFFF"
		}}>
			<div style={{ paddingTop: isMobile ? 80 : 75 }} />
			<div style={{ textAlign: "center", marginBottom: isMobile ? 20 : 50 }}>
				<Typography variant="h4" style={{ fontWeight: "bold", color: "#fff", marginBottom: 10 }}>
					Contact Us
				</Typography>
				<Typography variant="body3">
					Got any questions? We are here to help!
				</Typography>
			</div>
			<div style={{
				display: "flex",
				flexDirection: isMobile ? "column-reverse" : null,
				borderRadius: "16px",
				backgroundColor: "#2f2f2f",
				overflow: "hidden",
			}}>
				<div style={{
					flex: isMobile ? "1 1 auto" : "1 1 300px",
					padding: isMobile ? "10px" : "16px",
					backgroundColor: "#212121",
					color: "#fff",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}>
					<div style={{
						flex: isMobile ? "1 1 auto" : "1 1 300px",
						padding: isMobile ? "20px" : "32px",
						backgroundColor: "#2F2F2F",
						color: "#fff",
						borderRadius: "16px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}>
						<Typography variant="h4" style={{ marginBottom: "20px", color: "#fff" }}>
							Contact<br /> Information
						</Typography>
						<div>
							<Typography variant="body2" style={{ color: "#fff", marginBottom: "10px" }}>
								Other ways to contact us:
							</Typography>
							<div style={{ display: "flex", marginBottom: 10 }}>
								<span style={{ marginRight: "10px", fontSize: "0.5rem" }}><ChatBubbleOutlineIcon /></span>
									<a
										href="#"
										onClick={(e) => {
											openDriftChat();
										}}
										style={{ color: "#FF8444", textDecoration: "none" }}
									>
										Shuffle Live Chat
									</a>
							</div>
							<div style={{ display: "flex", marginBottom: 10 }}>
								<span style={{ marginRight: "10px", fontSize: "0.5rem" }}><MailOutlineIcon /></span>
								<a href="mailto:support@shuffler.io" style={{ color: "#FF8444", textDecoration: "none" }}>
									support@shuffler.io
								</a>
							</div>
							<div style={{ display: "flex", marginBottom: 10 }}>
								<img style={{ marginRight: "10px", fontSize: "0.5rem" }} src='/images/icons/discordGray.svg' />
								<a href="https://discord.gg/B2CBzUm" style={{ color: "#FF8444", textDecoration: "none" }}>
									Join our Discord
								</a>
							</div>
						</div>
					</div>
				</div>
				{step === 1 ? 
				<div style={{
					flex: isMobile ? "1 1 auto" : "2 1 600px",
					padding: isMobile ? "20px" : "40px",
					backgroundColor: "#212121",
					color: "#fff",
				}}>
					<div style={{
						display: "flex",
						flexWrap: "wrap",
						gap: "15px",
						marginBottom: "20px",
						flexDirection: isMobile ? "column" : "row"
					}}>
						<div style={{ flex: "1 1 45%" }}>
							<Typography variant="body2" style={{ color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
								First Name
							</Typography>
							<TextField
								required
								InputProps={{ style: { color: "#fff" } }}
								color="primary"
								fullWidth
								placeholder="First Name"
								type="text"
								margin="normal"
								variant="outlined"
								onChange={e => setFirstname(e.target.value)}
							/>
						</div>
						<div style={{ flex: "1 1 45%" }}>
							<Typography variant="body2" style={{ color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
								Last Name
							</Typography>
							<TextField
								required
								InputProps={{ style: { color: "#fff" } }}
								color="primary"
								fullWidth
								placeholder="Last Name"
								type="text"
								margin="normal"
								variant="outlined"
								onChange={e => setLastname(e.target.value)}
							/>
						</div>
					</div>
					<div style={{ display: "flex", flexWrap: "wrap", gap: "15px", marginBottom: "20px" }}>
						<div style={{ flex: "1 1 45%" }}>
							<Typography variant="body2" style={{ color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
								Email
							</Typography>
							<TextField
								required
								InputProps={{ style: { color: "#fff" } }}
								color="primary"
								fullWidth
								placeholder="example@email.com"
								type="email"
								margin="normal"
								variant="outlined"
								onChange={handleEmailChange}
								error={emailError}
								helperText={emailError ? "Invalid email address" : ""}
							/>
						</div>
						<div style={{ flex: "1 1 45%" }}>
							<Typography variant="body2" style={{ display: "flex", color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
								Job Title <div style={{ color: "#C5C5C5", marginLeft: 5 }}>(optional)</div>
							</Typography>
							<TextField
								InputProps={{ style: { color: "#fff" } }}
								color="primary"
								fullWidth
								placeholder="Enter your job title"
								type="text"
								margin="normal"
								variant="outlined"
								onChange={e => setTitle(e.target.value)}
							/>
						</div>
					</div>
					<div style={{ display: "flex", flexWrap: "wrap", gap: "15px", marginBottom: "20px" }}>
						<div style={{ flex: "1 1 45%" }}>
							<Typography variant="body2" style={{ color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
								Company Name
							</Typography>
							<TextField
								InputProps={{ style: { color: "#fff" } }}
								color="primary"
								fullWidth
								placeholder="Enter your company name"
								type="text"
								margin="normal"
								variant="outlined"
								onChange={e => setCompany(e.target.value)}
							/>
						</div>
						<div style={{ flex: "1 1 45%" }}>
							<Typography variant="body2" style={{ color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
								Category
							</Typography>
							<FormControl fullWidth margin="normal" variant="outlined">
								<InputLabel id="category-label">Select a Category</InputLabel>
								<Select
									labelId="category-label"
									value={category || ""}
									onChange={handleChange}
									label="Select a Category"
									style={{ color: "#fff" }}
								>
									<MenuItem value="" disabled>Select a Category</MenuItem>
									{Object.values(categoryMap).map(option => (
										<MenuItem key={option} value={option} style={{ color: "#fff" }}>
											{option}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>
					<div style={{ marginBottom: "20px" }}>
						<Typography variant="body2" style={{ color: "#fff", marginBottom: "5px", fontWeight: "bold" }}>
							Message
						</Typography>
						<TextField
							multiline
							rows="4"
							InputProps={{ style: { color: "#fff" } }}
							color="primary"
							fullWidth
							placeholder="Let us know how we can help you"
							type="text"
							margin="normal"
							variant="outlined"
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
								disabled={
									firstname.length <= 0 ||
									email.length <= 0 ||
									message.length <= 0 ||
									category.length <= 0 ||
									emailError 
								}
								style={{
									width: 220,
									borderRadius: "8px",
									height: "50px",
									backgroundColor:
										firstname.length > 0 &&
										email.length > 0 &&
										message.length > 0 &&
										category.length > 0 &&
										!emailError
											? "#FF8544"
											: "#A9A9A9",
									textDecoration: "none",
									textTransform: "capitalize",
									fontSize: "16px",
									color: "#000000",
								}}
								variant="contained"
								onClick={submitContact}
							>
								Submit Form
							</Button>
					</div>
					<h3 style={{ textAlign: "center" }}>{formMessage}</h3>
				</div>
				: 
				<div style={{
					flex: isMobile ? "1 1 auto" : "2 1 600px",
					padding: isMobile ? "20px" : "40px",
					backgroundColor: "#212121",
					color: "#fff",
				}}>
					{calendlyEmbed}
				</div> 
				}
			</div>
		</div>;

	const landingpageDataMobile =
		<div style={{ paddingBottom: "50px" }}>
			{minimize ?
				<div style={{ color: textcolor, textAlign: "center" }}>
					<Typography variant="h4" style={{ marginTop: 15, }}>
						<Trans t={t}>
							Contact us
						</Trans>
					</Typography>
				</div>
				:
				<div style={{ color: textcolor, textAlign: "center" }}>
					<h3 style={{}}>
						<Trans t={t}>
							Contact us
						</Trans>
					</h3>
					<h2>
						<Trans t={t}>
							What can we do for you?
						</Trans>
					</h2>
				</div>}
			<div style={{ display: "flex", marginTop: 10, }}>
				<Paper elevation={mini === true ? 0 : 0} style={boxStyle}>
					<Typography variant="h6" style={{ color: textcolor }}>Contact Details</Typography>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Name"
							type="firstname"
							id="firstname"
							autoComplete="firstname"
							margin="normal"
							variant="outlined"
							onChange={e => setFirstname(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Email"
							type="email"
							id="email-field-contact"
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={handleEmailChange}
							error={emailError}
							helperText={emailError ? "Invalid email address" : ""}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ marginRight: "15px", }}
							InputProps={{
								style: {
									color: textcolor
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Company"
							type="text"
							id="company"
							margin="normal"
							variant="outlined"
							onChange={e => setCompany(e.target.value)}
						/>
						<FormControl fullWidth margin="normal" variant="outlined" style={{ textAlign: "start" }}>
							<InputLabel id="category-label">Select Category</InputLabel>
							<Select
								labelId="category-label"
								value={category || ""}
								onChange={handleChange}
								label="Select Category"
							>
								<MenuItem value="" disabled>
									Select Category
								</MenuItem>
								{Object.values(categoryMap).map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div style={{ flex: 1, marginTop: 20, }}>
						<Typography variant="h6" style={{ color: textcolor }}>Message</Typography>
					</div>
					<div style={{ flex: 4 }}>
						<TextField
							multiline
							style={{ flex: "1", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							rows="6"
							fullWidth={true}
							placeholder="What can we help you with?"
							id="filled-multiline-static"
							type="message"
							margin="normal"
							variant="outlined"
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					<Button
						disabled={email.length <= 0 || message.length <= 0 || emailError}
						style={{ width: "100%", height: "60px", marginTop: "10px" }}
						variant="contained"
						color="primary"
						onClick={submitContact}
					>
						Submit
					</Button>
					<h3 style={{ textAlign: "center" }}>{formMessage}</h3>
				</Paper>
			</div>
		</div>

	const loadedCheck = (
		<div style={containerStyle}>
			<div style={swipeStyle}>
				{landingpageDataBrowser}
			</div>
		</div>
	);

	return (
		<div>
			{loadedCheck}
		</div>
	)
}
export default Contact;
